@use './partials/variables' as *;
@use './partials/mixins' as *;

.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include desktop {
    max-width: 1320px;
    margin: 1rem auto;

  }
  font-family: $font-primary;
  &__img {
    
    background-image: url('../assets/images/rubbeducky2.png');
    background-size: cover;
    height: 100px;
    width: 100px;
  }
  &__titleBanner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__p{
    width: 80%;
    text-align: center;
  }
  &__titleText{
    font-size: 1rem;
    @include tablet{
    font-size: 1.5rem;}
  }
}
.gameboard{
  width: auto;
  height: auto;
  margin: 0 auto;
  &--div{
    display:flex;
    justify-content: center;
    align-items: center;
  }
}

//exterior cell
.gameboardcell {
  width:50px;
  height:50px;
  // background:blue;
  border: 1px solid black;
  position:relative;
}

//interior wave
.wave {
  position:absolute;
  width:100%;
  height:100%;
  left:0;
  top:cal(80%);
  background: url('../assets/images/wave.png');
  background-size: contain;
  background-color: $color-light;
  transition: all 5s;
  z-index:-1;
  animation:wave 1.5s linear infinite;
  

}
.num{
  z-index: 2;
  color:black;
}
//interior wave
// .wave:before {
//   content:"";
//   position:absolute;
//   width:100%;
//   height:10px;
//   left:0;
//   top:-10px;
//   background: url('../assets/images/wave.png');
//   background-size: cover;
//     animation:wave 1.5s linear infinite;
// }

@keyframes wave {
  0% {
    background-position-x: 0 ;
  }
  100% {
    background-position-x:35px;
  }
  
}
.gameboardrow{
  background:purple;
}

.outer-bounds {
  width:50px;
  height:50px;
  background:rgb(255, 255, 255);
  border: 1px solid black;
}


.ducky {
  background: $color-light;
  background-image: url('../assets/images/rubbeducky2.png');
  background-size: cover;
  width:50px;
  height:50px;
   border: 1px solid black;
  
}
.bridge {
  background: $color-dark;
  background-image: url('../assets/images/wave.png');
  background-size: cover;
  width:50px;
  height:50px;
  border: 1px solid rgb(0, 0, 0);
  background-size: contain;
  z-index:-1;
  animation:wave 1.5s linear infinite;
  transition: all 5s;
  
}
.finish {
  background:rgb(119, 3, 3);
  background-image: url('../assets/images/wave.png');
  background-size: cover;
  width:50px;
  height:50px;
  border: 1px solid rgb(0, 0, 0);
  background-size: contain;
  z-index:-1;
  animation:wave 1.5s linear infinite;
  transition: all 5s;
  
}
.preview {
  background: $color-light-transparent;
  background-image: url('../assets/images/wave.png');
  background-size: cover;
  width:50px;
  height:50px;
  background-size: contain;
  z-index:-1;
  animation:wave 1.5s linear infinite;
  // transition: all 5s;
  border: 1px solid yellow;
}
.form {
  margin-top: 1rem;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  &--top {
    margin:0;
    border-top:1px solid black;
    width: 300px;
    font-size: 1rem;
    border-right:1px solid black;
    border-left:1px solid black;
    padding: .5rem;
    font-family:Arial, sans-serif;
  }
  &--bottom {
    margin:0;
    border-bottom:1px solid black;
  }
  &--text {
    font-size: 1rem;
    border-right:1px solid black;
    border-left:1px solid black;
    width: 300px;
    padding: .5rem;
  }
}


input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  border: 0px solid;
  background-color: rgb(214, 219, 219);;
}

.no-outline:focus {
  outline: none;
}

.main {
  display:flex;
  justify-content:space-between;
  align-items: center;
  &__sec {
    display:flex;
    flex-direction: column;
     
    // margin: 1rem auto;
    @include desktop {
      flex-direction:row;
      // margin: 0 4rem;
    }
  }
}

.gameboard-grid{
  display:flex;
  flex-direction:column;
}

.rules {
  width: 80%;
  display: flex;
  flex-direction: column;
  @include desktop {
    flex-direction: row;
  }
  &__div{
    width:100%;
    @include desktop {
      width:50%;
    }      
  }
  &__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    
    height: 100%;
    @include tablet{
      flex-direction: row;

    }
  }
  &__divEx1{
    width: 250px;
    height: 250px;
    background-image: url('../assets/images/pic02.png');
    background-size: cover;
    color:white;
    // background: black;
  }
  &__divEx2{
    margin-top: 1rem;
    width: 250px;
    height: 250px;
    background-image: url('../assets/images/pic03.png');
    background-size: cover;
    color:white;
    @include tablet {
      margin-top: 0;
    }
    // background: black;
  }
  &__divEx3{
    width: 250px;
    height: 250px;
    background-image: url('../assets/images/pic04.png');
    background-size: cover;
    color:white;
    // background: black;
  }
  &__title {
    
  }
  &__list {
    
  }
  &__item {
    
  }
}

.toUse{
  width: 100%;
  margin-top: 2rem;
  padding: 2rem;
  background: $color-light;
  color:white;
  flex-wrap: wrap;
  line-height: 2rem;
  &__justify{
    width:90%;
  }
  &__direction {
    width:90%;
  }
  &__align {
    width:90%;
  }
  &__highlight{
    background: $color-light-sec;
    padding: .25rem;
    color:$color-accent-sec;
  }
  @include desktop {
    width: 70%;
    margin-right:2rem;
    padding: 2rem;
  }
  
}

.outside-die {
  background: tomato;
  border-radius:10%;
  width: 104px;  
  height: 104px; 
  display:flex;
  justify-content:center;
  align-items:center;
  padding: 4px; 
}


.inside-die {
  font-size: 2rem;
  color:white;
  
}

.game {
  width:100%;
  &__title{
    margin-top:2rem;
    text-align: center;
  }
  &__buttons{
    width: 60%;
    margin: 2rem auto 0;
    &--remove {
      display: flex;
      justify-content: center;
    }
    &--direction{
      margin-left: 1rem;
    }
    
  }
  @include desktop {
    width:70%;
  }

}

.button{
  
  &__dir{
   
    background: $color-light;
    color:white;
    padding: 1rem 2rem;
    border-radius: 10px;
    width: 90px;
    height: 50px;
    &:hover{
      background: rgb(134, 134, 241);
    }
  }
    &__blank{
      background: white;
    padding: 1rem 2rem;
    border-radius: 10px;
    width: 90px;
    height: 50px;
    border: none;
    }
    &__remove{
      background: tomato;
      color:white;
      padding: 1rem 2rem;
      border-radius: 10px;
      width: 90px;
      height: 50px;
      &:hover{
        background: rgb(243, 120, 120);
      }
    }
    &__submit {
      display:flex;
      justify-content: center;
      align-items: center;
      border: 1px solid black;
      box-shadow: 3px 3px  $color-light-sec;
      margin-top:1rem;
      background: tomato;
      color:white;
      padding: 1rem 2rem;
      border-radius: 10px;
      width: 90px;
      height: 50px;
      font-size: 1.5rem;
      &:hover{
        background: rgb(243, 120, 120);
      }
    } 
    &__toggle {
      border:none;
      background: $color-light;
      cursor: pointer;
    }
  }



  
.yes {
  color: rgb(51, 46, 196);
}

.no {
  color:rgb(119, 3, 3)
}