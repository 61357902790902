@use './variables' as *;

@mixin tablet {
    @media (min-width: $tablet-width) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop-width) {
        @content;
    }
}